import {lazy} from 'react';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import {Navigate} from "react-router-dom";

// dashboard routing
const Home = Loadable(lazy(() => import('../views/Home')));
const Race = Loadable(lazy(() => import('../views/Race')));
const Contact = Loadable(lazy(() => import('../views/Contact')));
const Dcma = Loadable(lazy(() => import('../views/Dcma')));
const Privacy = Loadable(lazy(() => import('../views/Privacy')));
const Rules = Loadable(lazy(() => import('../views/Rules')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout/>,
    children: [
        {path: '/', element: <Home/>},
        {path: '/fight/:id/:slug', element: <Race/>},
        {path: '/contact', element: <Contact/>},
        {path: '/rules', element: <Rules/>},
        {path: '/dcma', element: <Dcma/>},
        {path: '/privacy', element: <Privacy/>},
        {path: '*', element: <Navigate to="/" replace />}
    ]
};

export default MainRoutes;