import {combineReducers} from 'redux';

// reducer import
import dataReducer from './dataReducer';
import configReducer from "./configReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    data: dataReducer,
    config: configReducer
});

export default reducer;